import * as React from "react";
import Layout from "@components/layout";

const PaymentProcessPage = () => {
	return (
		<Layout
			pageTitle="About"
			hideHeader
			hideFooter
			hideChat
			headerBg="bg-purple-50">
			<section className="relative h-screen md:pb-24 lg:pb-22 overflow-hidden bg-purple-50">
				<div className="relative lg:pt-16 pb-28">
					<div className="container px-4 mx-auto mt-32 mb-4 text-center">
						<h2 className="mt-8 mb-12 text-4xl lg:text-4xl font-semibold">
							Transaction completed
						</h2>
						<p className="max-w-3xl mt-5 mx-auto mb-12 text-md text-gray-700">
							Your payment has been securely completed. Money moves in
							mysterious ways, but this time, it's straight into the right
							hands. Thanks for choosing us.
						</p>
						<div className="mt-4">
							<button
								type="button"
								className="inline-flex justify-center rounded-md border border-purple-500 bg-white px-20 py-3 text-sm font-medium text-purple-500"
								onClick={() => {
									window.location = "/payments/process";
								}}>
								Go home
							</button>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default PaymentProcessPage;
